import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import { graphql, Link } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

import Layout from 'components/Layout'
import SEO from 'components/SEO'
import HeaderGallery from 'components/HeaderGallery'

import s from './production-partner.module.css'

const ProductionPartner = ({ data }) => {
  const {
    seo: { title: seoTitle, description: seoDescription },
    acfProductionPartner: { productionPartnerPageHeader, productionPartners },
  } = data.wordpressPage
  return (
    <Layout>
      <Helmet>
        <html data-theme-color="ebb" />
      </Helmet>
      <SEO title={seoTitle} description={seoDescription} />
      <HeaderGallery data={productionPartnerPageHeader} />
      <div className={s.container}>
        {productionPartners && (
          <div className={s.partners}>
            {productionPartners.map(
              (
                {
                  acfProductionPartnerPost: {
                    productionPartnerPostHeader: { title, description },
                    shopifyCollectionHandle,
                  },
                  featuredImage,
                },
                i
              ) => {
                return (
                  <Link
                    key={`${
                      title || description || featuredImage?.node?.localFile?.id
                    }-${i}`}
                    to={`/collection/${shopifyCollectionHandle}`}
                    className={s.partner}
                  >
                    {featuredImage && (
                      <GatsbyImage
                        className={s.img}
                        image={getImage(featuredImage.node.localFile)}
                        alt=""
                        backgroundColor="#eee"
                      />
                    )}
                    {title && (
                      <h2
                        className={s.title}
                        dangerouslySetInnerHTML={{ __html: title }}
                      />
                    )}
                    {description && (
                      <div
                        className={s.description}
                        dangerouslySetInnerHTML={{ __html: description }}
                      />
                    )}
                    <span className={s.label}>View Collection</span>
                  </Link>
                )
              }
            )}
          </div>
        )}
      </div>
    </Layout>
  )
}

export default ProductionPartner

ProductionPartner.propTypes = {
  data: PropTypes.shape({
    wordpressPage: PropTypes.shape({
      seo: PropTypes.shape({
        title: PropTypes.string,
        description: PropTypes.string,
      }),
      acfProductionPartner: PropTypes.shape({
        productionPartnerPageHeader: PropTypes.object,
        productionPartners: PropTypes.arrayOf(
          PropTypes.shape({
            acfProductionPartnerPost: PropTypes.shape({
              productionPartnerPostHeader: PropTypes.shape({
                title: PropTypes.string,
                description: PropTypes.string,
              }),
              shopifyCollectionHandle: PropTypes.string,
            }),
            featuredImage: PropTypes.object,
          })
        ),
      }),
    }),
  }),
}

export const pageQuery = graphql`
  query ProductionPartnerByID($id: String!) {
    wordpressPage(id: { eq: $id }) {
      seo {
        title
        description
      }
      acfProductionPartner {
        productionPartnerPageHeader {
          title
          description
          images {
            mobile {
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 800, placeholder: NONE)
                }
              }
            }
            desktop {
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 800, placeholder: NONE)
                }
              }
            }
          }
        }
        productionPartners {
          ... on WordpressProductionPartner {
            acfProductionPartnerPost {
              productionPartnerPostHeader {
                title
                description
              }
              shopifyCollectionHandle
            }
            featuredImage {
              node {
                localFile {
                  childImageSharp {
                    gatsbyImageData(width: 800, placeholder: NONE)
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
