import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import HeaderGalleryImages from 'components/HeaderGalleryImages'
import LinkWordPress from 'components/LinkWordPress'

import s from './styles.module.css'

const HeaderGallery = ({ data, children }) => {
  const {
    images,
    title,
    description,
    linkType,
    link,
    productLinkType,
    collectionHandle,
    productHandle,
    linkLabel,
  } = data
  return (
    <div className={s.wrapper}>
      <div className={s.container}>
        <div className={classNames(s.col, s.colImg)}>
          <HeaderGalleryImages images={images} />
        </div>
        <div className={classNames(s.col, s.colText)}>
          <div className={s.spacing} />
          <div className={s.details}>
            {title && (
              <h1
                className={s.title}
                dangerouslySetInnerHTML={{ __html: title }}
              />
            )}
            {description && (
              <div
                className={s.description}
                dangerouslySetInnerHTML={{ __html: description }}
              />
            )}
            <LinkWordPress
              className={s.link}
              linkType={linkType}
              link={link}
              productLinkType={productLinkType}
              collectionHandle={collectionHandle}
              productHandle={productHandle}
              linkLabel={linkLabel}
            />
            {children}
          </div>
        </div>
      </div>
    </div>
  )
}

HeaderGallery.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
    linkType: PropTypes.string,
    link: PropTypes.shape({
      target: PropTypes.string,
      title: PropTypes.string,
      url: PropTypes.string,
    }),
    productLinkType: PropTypes.string,
    collectionHandle: PropTypes.string,
    productHandle: PropTypes.string,
    linkLabel: PropTypes.string,
    images: PropTypes.array,
  }),
  children: PropTypes.node,
}
HeaderGallery.defaultProps = {
  title: '',
  description: '',
  linkType: '',
  link: {},
  productLinkType: '',
  collectionHandle: '',
  productHandle: '',
  linkLabel: '',
  images: [],
  children: null,
}

export default HeaderGallery
