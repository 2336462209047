import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

import s from './styles.module.css'

function HeaderGalleryImages({ images, className }) {
  if (!images || images?.length < 1) return null

  const renderImg = (img, classname) => {
    if (!img) return null

    return (
      <div className={classNames(s.img, classname)}>
        <GatsbyImage
          image={getImage(img.localFile)}
          loading="eager"
          alt="gallery"
        />
      </div>
    )
  }

  return (
    <div className={classNames(s.container, className)}>
      {renderImg(images[0].mobile, s.img34)}
      {renderImg(images[0].desktop, s.img1610)}
    </div>
  )
}

HeaderGalleryImages.propTypes = {
  images: PropTypes.arrayOf(
    PropTypes.shape({
      mobile: PropTypes.shape({
        localFile: PropTypes.shape({
          childImageSharp: PropTypes.object,
        }),
      }),
      desktop: PropTypes.shape({
        localFile: PropTypes.shape({
          childImageSharp: PropTypes.object,
        }),
      }),
    })
  ),
  className: PropTypes.string,
}
HeaderGalleryImages.defaultProps = {
  images: [],
  className: '',
}

export default HeaderGalleryImages
